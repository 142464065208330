import React from "react";

function useremailaccount() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="25"
      fill="none"
      viewBox="0 0 32 25"
    >
      <path
        stroke="#139DFF"
        strokeOpacity="0.5"
        strokeWidth="2.33"
        d="M26.5 2h-21A3.5 3.5 0 002 5.5v14A3.5 3.5 0 005.5 23h21a3.5 3.5 0 003.5-3.5v-14A3.5 3.5 0 0026.5 2z"
      ></path>
      <path
        stroke="#139DFF"
        strokeOpacity="0.5"
        strokeWidth="2.33"
        d="M2 7.25l12.435 6.218a3.5 3.5 0 003.13 0L30 7.25"
      ></path>
    </svg>
  );
}

export default useremailaccount