import React from "react";

function FancyUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="28"
      fill="none"
      viewBox="0 0 34 28"
    >
      <path
        stroke="#139DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.263"
        d="M2 26s4.326-5.532 14.999-5.532C27.672 20.468 32 26 32 26M16.999 13.664a5.817 5.817 0 004.117-1.708 5.837 5.837 0 000-8.248 5.817 5.817 0 00-8.234 0 5.837 5.837 0 000 8.248A5.817 5.817 0 0017 13.664z"
      ></path>
    </svg>
  );
}

export default FancyUserIcon;
