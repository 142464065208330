import React from "react";

function UserLockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      fill="none"
      viewBox="0 0 18 21"
    >
      <path
        fill="#000"
        fillOpacity="0.7"
        d="M16.639 7.656h-3.21V5.201a4.343 4.343 0 00-8.686 0v2.455h-3.21A1.322 1.322 0 00.21 8.978v10.574a1.322 1.322 0 001.322 1.322h15.106a1.322 1.322 0 001.322-1.322V8.978a1.322 1.322 0 00-1.322-1.322zM5.876 5.201a3.21 3.21 0 116.42 0v2.455h-6.42V5.201zm10.952 14.351a.189.189 0 01-.189.19H1.533a.189.189 0 01-.19-.19V8.978a.189.189 0 01.19-.189h15.106a.189.189 0 01.189.189v10.574zm-6.798-5.287a.944.944 0 11-1.888 0 .944.944 0 011.888 0z"
      ></path>
    </svg>
  );
}

export default UserLockIcon;