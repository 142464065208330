import React from "react";

function RingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="28"
      fill="none"
      viewBox="0 0 25 28"
    >
      <path
        fill="#139DFF"
        fillOpacity="0.5"
        d="M9.917 25.126h5.166a2.59 2.59 0 01-2.583 2.583 2.59 2.59 0 01-2.583-2.583zm14.208-2.584v1.292H.875v-1.292l2.583-2.583v-7.75c0-4.004 2.584-7.492 6.459-8.654v-.388A2.59 2.59 0 0112.5.584a2.59 2.59 0 012.583 2.583v.388c3.875 1.162 6.459 4.65 6.459 8.654v7.75l2.583 2.583zM18.958 12.21c0-3.617-2.841-6.458-6.458-6.458s-6.458 2.841-6.458 6.458v9.042h12.916v-9.042z"
      ></path>
    </svg>
  );
}

export default RingIcon;
