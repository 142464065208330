import React from "react";

function userLogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#000"
        fillOpacity="0.6"
        d="M12.471 24.167v-1.459h9.34c.224 0 .43-.093.618-.28.187-.186.28-.392.28-.618V3.19c0-.224-.094-.43-.28-.618-.187-.188-.393-.281-.619-.28h-9.337V.833h9.339c.67 0 1.23.226 1.68.676.449.45.674 1.01.675 1.681v18.622c0 .67-.225 1.23-.675 1.681-.45.45-1.01.675-1.68.674h-9.34zm-1.035-6.506l-1.025-1.05 3.383-3.382H.86v-1.458h12.933L10.41 8.388l1.023-1.047 5.161 5.16-5.16 5.16z"
      ></path>
    </svg>
  );
}

export default userLogoutIcon;
