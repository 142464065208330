import React from "react";

function FilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      fill="none"
      viewBox="0 0 24 16"
    >
      <path
        stroke="#BFBFBF"
        strokeLinecap="round"
        strokeWidth="2"
        d="M.998 1.583h22.001M4.666 8h14.667M7.875 14.417h8.25"
      ></path>
    </svg>
  );
}

export default FilterIcon;