import React from "react";

function UserHistoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
      viewBox="0 0 18 22"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        d="M5.056 8.985h8.056m-8.056 8.057h8.056m-8.056-4.028h4.028m-8.057 7.452V1.533a.604.604 0 01.605-.604h11.734c.16 0 .314.064.427.177l3.17 3.17a.603.603 0 01.178.43v15.76a.606.606 0 01-.605.604H1.632a.605.605 0 01-.605-.604z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.7"
        strokeWidth="1.5"
        d="M13.113.929v3.424a.604.604 0 00.604.604h3.425"
      ></path>
    </svg>
  );
}

export default UserHistoryIcon;
