import React from "react";

function UserCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 14 11"
    >
      <path
        stroke="#139DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M1.75 5.75l3.5 3.5 7-7.5"
      ></path>
    </svg>
  );
}

export default UserCheck;
