import React from "react";

function UserProfile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        stroke="#139DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.294"
        d="M10.25 1.5A9.25 9.25 0 001 10.75 9.25 9.25 0 0010.25 20a9.25 9.25 0 009.25-9.25 9.25 9.25 0 00-9.25-9.25z"
      ></path>
      <path
        stroke="#139DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.294"
        d="M3.102 16.62s2.061-2.633 7.149-2.633c5.087 0 7.15 2.632 7.15 2.632m-7.15-5.87a2.775 2.775 0 100-5.55 2.775 2.775 0 000 5.55z"
      ></path>
    </svg>
  );
}

export default UserProfile;