import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#139DFF"
        d="M29.33 16.835a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.667 0z"
      ></path>
      <path
        fill="#139DFF"
        fillRule="evenodd"
        d="M20.147 41.818C9.036 41.372.164 32.222.164 21.001.164 9.495 9.491.168 20.997.168c11.507 0 20.834 9.327 20.834 20.833 0 11.507-9.327 20.834-20.834 20.834h-.285c-.189 0-.377-.006-.565-.017zM7.63 34.148a3.154 3.154 0 012.628-4.173c8.12-.899 13.41-.817 21.491.019a3.115 3.115 0 012.602 4.169A18.688 18.688 0 0039.747 21c0-10.355-8.394-18.75-18.75-18.75-10.355 0-18.75 8.395-18.75 18.75 0 5.121 2.054 9.763 5.382 13.146z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UserIcon;
